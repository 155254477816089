import React, { useEffect, useState } from "react";
import PaginatedItems from './PaginatedItems';
import axios from "axios";
import { allInOne, barkodOxuyucu, barkodPrinter, barkodProcessor, blog1, blog2, blog3, cardReader, dataTerminal, pulQutulari, svg1, svg10, svg11, svg12, svg13, svg14, svg15, svg16, svg2, svg3, svg4, svg5, svg6, svg7, svg8, svg9 } from '../../assets/index';

export const products = [
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  { 
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  { 
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog1,
    name: "Bio-L birtərəfli",
    path: "/blogs/1",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog2,
    name: "Bio-L birtərəfli",
    path: "/blogs/2",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },
  {
    image: blog3,
    name: "Bio-L birtərəfli",
    path: "/blogs/3",
    content: "On the other hand, we denounce with righteous indignation dislike men  ..."
  },

];

const Products = () => {
  // const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState("default");
  const [brandFilters, setBrandFilters] = useState({});
  const [kateqoriyaFilters, setKateqoriyaFilters] = useState({});
  const [priceFilter, setPriceFilter] = useState("default");
  const [brandSearch, setBrandSearch] = useState("");
  const [kateqoriyaSearch, setKateqoriyaSearch] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  // useEffect(() => {
  //   getProducts();
  // }, []);

  // const getProducts = async () => {
  //   try {
  //     const response = await axios.get("https://www.kcollection.az/kcollection/dashboard.php/", {
  //       withCredentials: false,
  //       headers: {
  //         'Cache-Control': 'no-cache',
  //       },
  //     });
  //     console.log(response.data);
  //     setProducts(response.data);
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.error("Error fetching products:", error);
  //   }
  // };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleBrandChange = (brand) => {
    setBrandFilters((prev) => ({
      ...prev,
      [brand]: !prev[brand],
    }));
  };

  const handleKateqoriyaChange = (kateqoriya) => {
    setKateqoriyaFilters((prev) => ({
      ...prev,
      [kateqoriya]: !prev[kateqoriya],
    }));
  };


  const handlePriceChange = (price) => {
    setPriceFilter(price);
  };

  const clearBrandFilters = () => {
    setBrandFilters({});
    setBrandSearch("");
  };
  const clearKateqoriyaFilters = () => {
    setKateqoriyaFilters({});
    setKateqoriyaSearch("");
  };

  // Filtering logic
  const filteredProducts = products.filter(product => {
    const matchesSearch = product.name.toLowerCase().includes(searchQuery.toLowerCase());

    // Check if brand filters are applied
    const hasSelectedBrands = Object.keys(brandFilters).some(key => brandFilters[key]);
    const matchesBrand = !hasSelectedBrands || brandFilters[product.brand];

        // Check if kateqoriya filters are applied
        const hasSelectedKateqoriya = Object.keys(kateqoriyaFilters).some(key => kateqoriyaFilters[key]);
        const matchesKateqoriya = !hasSelectedKateqoriya || kateqoriyaFilters[product.kateqoriya];



    return matchesSearch && matchesBrand && matchesKateqoriya;
  });

  // Sort products based on the selected option
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOption === "lowToHigh") {
      return a.price - b.price; // Sort ascending by price
    } else if (sortOption === "highToLow") {
      return b.price - a.price; // Sort descending by price
    }
    return 0; // No sorting
  });

  // Apply price filter
  const finalProducts = priceFilter === "default" ? sortedProducts : sortedProducts.filter(product => {
    if (priceFilter === "low") return product.price > 100 && product.price < 200; // Example: products priced below 50
    if (priceFilter === "high") return product.price > 200 && product.price < 300; // Example: products priced 50 and above
    if (priceFilter === "highh") return product.price > 300 && product.price < 400; // Example: products priced 50 and above
    if (priceFilter === "highhh") return product.price > 400 && product.price < 500; // Example: products priced 50 and above

    return true;
  });

  // Brands to display (for example purposes)
  const allBrands = [
    "Mefax", "Polino Bistech", "Ming gear", "Qliphor", "Linger",
    "Doowen"
  ];

   // Brands to display (for example purposes)
   const allKateqoriya = [
    "Kənd təsərrüfatı texnikası", "Aqreqatlar", "Tikinti texnikası", "Avtomobillər"
  ];

  // Filter brands based on search input
  const filteredBrands = allBrands.filter(brand =>
    brand.toLowerCase().includes(brandSearch.toLowerCase())
  );

   // Filter brands based on search input
   const filteredKateqoriya = allKateqoriya.filter(kateqoriya =>
    kateqoriya.toLowerCase().includes(kateqoriyaSearch.toLowerCase())
  );

  return (
    <>
      <div className='md:pb-[158px] pb-[40px] bg-[white] md:max-w-container max-w-containerSm mx-auto'>
      <div className='flex flex-col justify-center items-center md:mb-10 mb-5 mt-10'>
         <button className="w-[88px] bg-[#d4d64f] rounded-[32px] text-[white]">Məhsullar</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Kateqoriyalarımızı kəşf edın</p>
         </div>
        
        <div className="grid md:grid-cols-2 grid-cols-1 md:gap-8 gap-4">
<a href="/traktor/company">
<div className="h-[168px] bg-white rounded-[20px] border border-[#ececec] hover:bg-[#F5F9EE] group transition duration-300 ease-in-out">
 <div className="flex pl-6 pt-11">
<img className="w-[80px] h-[80px] mb-2 group-hover:hidden transition duration-300 ease-in-out" src={svg1} alt="icon" />
<img className="w-[80px] h-[80px] mb-2 hidden group-hover:block transition duration-300 ease-in-out" src={svg2} alt="icon hover" />
<div className="flex justify-center items-center">
<p className="ml-6 text-black md:text-[28px] text-[20px] font-medium">Traktorlar</p>
</div>
 </div> 
 </div> 
</a>
 <a href="/land/company">
 <div className="h-[168px] bg-white rounded-[20px] border border-[#ececec] hover:bg-[#F5F9EE] group transition duration-300 ease-in-out">
 <div className="flex pl-6 pt-11">
 <img className="w-[80px] h-[80px] mb-2 group-hover:hidden transition duration-300 ease-in-out" src={svg5} alt="icon" />
 <img className="w-[80px] h-[80px] mb-2 hidden group-hover:block transition duration-300 ease-in-out" src={svg6} alt="icon hover" />
<div className="flex justify-center items-center">
<p className="ml-6 text-black md:text-[28px] text-[20px] font-medium">Torpaq işləmə avadanlığı</p>
</div>
</div>
 </div>
 </a>
 <a href="/garden/company">
 <div className="h-[168px] bg-white rounded-[20px] border border-[#ececec] hover:bg-[#F5F9EE] group transition duration-300 ease-in-out">
 <div className="flex pl-6 pt-11">
 <img className="w-[80px] h-[80px] mb-2 group-hover:hidden transition duration-300 ease-in-out" src={svg7} alt="icon" />
 <img className="w-[80px] h-[80px] mb-2 hidden group-hover:block transition duration-300 ease-in-out" src={svg8} alt="icon hover" />
<div className="flex justify-center items-center">
<p className="ml-6 text-black md:text-[28px] text-[20px] font-medium">Bağçılıq</p>
</div>
</div>

 </div>
 </a>
 <a href="/harvest/company">
 <div className="h-[168px] bg-white rounded-[20px] border border-[#ececec] hover:bg-[#F5F9EE] group transition duration-300 ease-in-out">
 <div className="flex pl-6 pt-11">
 <img className="w-[80px] h-[80px] mb-2 group-hover:hidden transition duration-300 ease-in-out" src={svg9} alt="icon" />
 <img className="w-[80px] h-[80px] mb-2 hidden group-hover:block transition duration-300 ease-in-out" src={svg10} alt="icon hover" />
<div className="flex justify-center items-center">
<p className="ml-6 text-black md:text-[28px] text-[20px] font-medium">Məhsul yığım avadanlığı</p>
</div>
</div>
 </div>
 </a>
        </div>
      </div>
    </>
  );
};

export default Products;























