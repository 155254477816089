import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Sayfalandırma stillerini yüklemek için
import { Pagination, Scrollbar } from 'swiper/modules';
import './partners.css'

import { partners1,partners2, partners3, partners4, partners5, partners6, partners7, partners8, partners10 } from '../../../assets/index';
import { t } from 'i18next';

const Partners = () => {
  return (
    <div className='flex justify-center items-center my-[40px] md:my-[104px]'>   
      <div className='md:max-w-container max-w-containerSm flex flex-col mx-auto justify-between h-[240px]'>
      <div className='flex flex-col justify-center items-center mb-5 md:mb-10'>
         <button className="w-[113px] bg-[#d4d64f] rounded-[32px] text-[white]">{t("Distribyutor")}</button>
         <p className="text-black text-[22px] md:text-[32px] font-medium text-center ">{t("Distribyutor olduğumuz şirkətlər")}</p>
         </div>
        <div className='h-[145px]'>
          <Swiper
            spaceBetween={30}        // Görseller arasındaki boşluk
            slidesPerView={4}         // Varsayılan olarak aynı anda görünen slayt sayısı
            loop={true}               // Slider sonsuz döngüde olsun
            modules={[Pagination]}    // Pagination modülünü yükle
            breakpoints={{
              // Ekran boyutu 640px'den küçükse 1 slayt göster
              340: {
                slidesPerView: 1,
              },
              // Ekran boyutu 640px veya daha büyükse 4 slayt göster
              1024: {
                slidesPerView: 4,
              },
            }}
          >
            {/* Tüm partner görselleri için SwiperSlide bileşeni */}
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners1} alt="Partner 1" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners2} alt="Partner 2" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners3} alt="Partner 4" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners4} alt="Partner 5" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners5} alt="Partner 6" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners6} alt="Partner 7" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners7} alt="Partner 8" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners8} alt="Partner 9" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className='h-36   flex justify-center items-center'>
                <img className='h-[100px] object-contain' src={partners10} alt="Partner 11" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Partners;