import React from 'react';
import { footerLogo, hero, icon1, icon10, icon2, icon3, icon4, icon5, icon6, icon7, icon8, icon9 } from '../../assets';
import { useTranslation } from 'react-i18next';


const Footer = () => {

  const { t, i18n } = useTranslation();
  return (
    <footer className="bg-[#F1F1F1] text-gray-800 md:py-8">
      <div className="md:max-w-container max-w-containerSm mx-auto md:flex justify-between pt-[32px] md:pt-0">
        <div className='md:w-[310px]'>
          <h3 className="text-xl font-semibold md:mb-2"><img className="w-[143px] h-[91px]" src={footerLogo}></img></h3>
          <p className='text-sm md:text-base'>{t("Kənd Təsərrüfatında Məhsuldarlığınızı Yüksəltmək üçün Yüksək Keyfiyyətli və Etibarlı İnnovativ Həlləri")}</p>
        </div>
        <div className='w-[130px] mt-5 md:mt-0'>  
          <h4 className="text-lg font-semibold mb-2">{t("Əsas keçidlər")}</h4>
          <ul >
            <li className='mb-2'><a href="/" className="hover:underline mb-2">{t("Ana səhifə")}</a></li>
            <li className='mb-2'><a href="/products" className="hover:underline mb-2">{t("Məhsullar")}</a></li>
            <li className='mb-2'><a href="/about" className="hover:underline  mb-2">{t("Haqqımızda")}</a></li>
            <li className='mb-2'><a href="/services" className="hover:underline  mb-2">{t("Xidmətlər")}</a></li>
            <li className='mb-2'><a href="/news" className="hover:underline  mb-2">{t("Xəbərlər")}</a></li>
            <li><a href="/contact" className="hover:underline">{t("Əlaqə")}</a></li>
          </ul>
        </div>
        <div className='md:w-[342px] mt-4 md:mt-0'>
          <div>
          <h4 className="text-lg font-semibold mb-2">{t("Əlaqə")}</h4>
          <div className='flex gap-2 mb-[29px]'>
<a href='/'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={icon1} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={icon6} alt="icon hover" />
</div>
</a>
<a href='https://www.instagram.com/agrogurama?igsh=MTV1dzJpNnFtN3Jxdw=='>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={icon2} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={icon7} alt="icon hover" />
</div>
</a>
<a href='/'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={icon3} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={icon8} alt="icon hover" />
</div>
</a>
<a href='https://www.facebook.com/agroguramallc?mibextid=LQQJ4d'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={icon4} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={icon9} alt="icon hover" />
</div>
</a>
<a href='https://www.tiktok.com/@agrogurama?_t=8plWovrMOZw&_r=1'>
<div className='group transition duration-300 ease-in-out'>
    <img className="w-6 h-6  group-hover:hidden" src={icon5} alt="icon" />
    <img className="w-6 h-6  hidden group-hover:block" src={icon10} alt="icon hover" />
</div>
</a>
</div>

        <h4 className="text-lg font-semibold mb-2">{t("Təklifinizi yazın")}</h4>
 <div className='flex'>
 <textarea
    type="text"
    id="company"
    className="w-full h-10 p-2.5 bg-white rounded-l-xl border border-[#bcbcbc] justify-start items-center gap-2.5 inline-flex text-sm focus:outline-none transition-all duration-300 resize-none overflow-hidden"
    placeholder={t("Bura qeyd edin...")}
    rows="1"
    maxLength={200} // Karakter sınırı burada
    onInput={(e) => {
      e.target.style.height = "auto";
      e.target.style.height = `${e.target.scrollHeight}px`;

      // Karakter sınırı kontrolü
      if (e.target.value.length > 200) {
        e.target.value = e.target.value.slice(0, 200);
      }
    }}
  />
  <div className="w-[148px] h-10 p-2.5 bg-[#0d4b2c] rounded-r-xl justify-center items-center gap-2.5 inline-flex text-white text-sm">
    {t("Göndər")}
  </div>
 </div>
</div>



        </div>
      </div>
      <div className='h-[1px] bg-[#B8B8B8] mt-5'></div>
      <div className="md:max-w-[1260px] max-w-containerSm md:flex justify-between mt-3 md:mt-8 md:text-center text-sm mx-auto pb-5 md:pb-0">
       <p> Agrogurama © {t("Bütün hüquqlar qorunur.")}</p>
       <p> {t("Sayt hazırlandı")}: Birsayt.az</p>
      </div>
    </footer>
  );
};

export default Footer;