import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { about, carousel1, carousel2, carousel3, carousel4, carousel5, hero, hero1, hero2, hero3, hero4, hero5 } from "../../../assets/index";
import { useTranslation } from "react-i18next";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Hero = () => { 
 
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0); 
  const [showText, setShowText] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);


  const slides = [
    { img: hero1, text: "Rahatlığı məkanınızın bir küncündə hiss edin", path: 'sofa' },
    { img: hero2, text: "Rahatlığı məkanınızın bir küncündə hiss edin", path:'top' },
    { img: hero3, text: "Rahatlığı məkanınızın bir küncündə hiss edin", path:'komfort' },
    { img: hero4, text: "Rahatlığı məkanınızın bir küncündə hiss edin", path: 'klassik' },
    { img: hero5, text: "Rahatlığı məkanınızın bir küncündə hiss edin", path: 'klassik' },
  ];

  useEffect(() => {
    const textTimer = setTimeout(() => {
      setShowText(true);
    }, 500);

    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 1500);

    return () => {
      clearTimeout(textTimer);
      clearTimeout(buttonTimer);
    };
  }, [activeIndex]); 

  
  return (
    <div className="md:max-w-container max-w-containerSm mt-[44px] mx-auto">
   <p className="text-black md:text-[46px] text-[22px] font-bold tracking-wide mb-5">{t("Kənd Təsərrüfatında məhsuldarlığınızı yüksəltmək üçün keyfiyyətli və etibarlı ")} <span className="text-[#0D4B2C]">{t("innovativ həllər")}</span></p>
   {/* <img className="md:h-[665px]" src={hero}></img> */}
   <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      onSlideChange={(swiper) => {
        setShowText(false);
        setShowButton(false);
        setActiveIndex(swiper.activeIndex);
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
     {slides.map((slide, index) => (
  <SwiperSlide key={index}>
    <div className="relative">
      {/* Mor arka plan */}
      <div className="h-[665px] md:flex items-center justify-center">
        {/* Sağ üst köşedeki metin */}
        <div className="md:absolute md:top-0 md:right-0 md:m-4 text-[#282828] md:w-[45%]">
        {t("Müasir texnologiya və güvənli xidmətlərlə təsərrüfatınızı bir addım irəli aparın. AgroGurama ilə kənd təsərrüfatı işləriniz daha məhsuldar və effektivdir.")}
        </div>
        
        {activeIndex === index && (
          <div className="md:max-w-container max-w-containerSm absolute inset-0 block md:flex md:top-0 top-28 justify-center mt-4 md:mt-0 items-center text-white transition-opacity duration-1000 mx-auto">
            <div className="w-full flex justify-center md:justify-end"> 
              <img
                className="object-contain md:h-[665px]"
                src={slide.img}
                alt="Mebel"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  </SwiperSlide>
))}

    </Swiper>
    </div>
  );
};

export default Hero;
