// import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Autoplay, Pagination, Navigation } from 'swiper/modules';
// import './Services.css'
// import { ehtiyyat, hero, kataloq1, kataloq2, kataloq3, kataloq4, kataloq5, konsultasiya1, konsultasiya2, konsultasiya3, konsultasiya4, konsultasiya5, satis1, satis2, services1, services2, services3, services4, services5, texnika1, texnika2, texnika3, texnika4, texnika5, texnika6 } from "../../assets";

// export default function Services() {

//    const [activeIndex, setActiveIndex] = useState(0);
//    const [showText, setShowText] = useState(false);
//    const [showButton, setShowButton] = useState(false);

//   const { t, i18n } = useTranslation();


//   const konsultasiya = [
//    { img: konsultasiya1 },
//    { img: konsultasiya2 },
//    { img: konsultasiya3 },
//    { img: konsultasiya4},
//    { img: konsultasiya5},
//  ]; 
//  const texnika = [
//   { img: texnika1 },
//   { img: texnika2 },
//   { img: texnika3 },
//   { img: texnika4},
//   { img: texnika5},
//   { img: texnika6},
// ]; 
// const satis = [
//   { img: satis1 },
//   { img: satis2 },
// ]; 
//   return ( 
//     <>
//        <div className="max-w-containerSm md:max-w-container mt-[40px] mx-auto mb-[40px] md:mb-[132px]">
//                <div className='flex flex-col justify-center items-center'>
//          <button className="w-[120px] bg-[#d4d64f] rounded-[32px] text-[white]">Xidmətlər</button>
//          <p className="text-black text-[22px] md:text-[32px] font-medium text-center">Ehtiyaclarınıza uyğun xidmətlər</p>
//          <p className="text-black text-base font-normal mt-[20px] md:mt-[40px] mb-[20px] md:mb-[60px]">AgroGurama kənd təsərrüfat şirkəti, aqreqatlarınızın uzun müddət və səmərəli işləməsini təmin etmək üçün mükəmməl texniki xidmət və təmir xidmətləri təqdim edir. Bizim mütəxəssislərimiz, avadanlıqlarınızın ən yaxşı vəziyyətdə qalmasını təmin etmək üçün aşağıdakı xidmətləri həyata keçirir:</p>
//          </div>
//   <div className="flex flex-col-reverse md:flex-row items-center md:mt-[40px] ">
//   <div className="md:w-[586px] flex flex-col justify-end mt-[20px] md:mt-0">
// <Swiper
//   spaceBetween={30}
//   centeredSlides={true}
//   autoplay={{
//     delay: 5000,
//     disableOnInteraction: false,
//   }}
//   pagination={{
//     clickable: true,
//   }}
//   navigation={true}
//   onSlideChange={(swiper) => {
//     setShowText(false);
//     setShowButton(false);
//     setActiveIndex(swiper.activeIndex);
//   }}
//   modules={[Autoplay, Pagination, Navigation]}
//   className="servicesSwiper1"
// >
//   {konsultasiya.map((slide, index) => (
//     <SwiperSlide key={index}>
//       <div className="relative">
//         {/* Mor arka plan */}
//         <div>
//           {activeIndex === index && (
//             <div>
//               <div className="text-black text-lg font-normal mb-3">
//               <p className={`text-black md:text-lg font-normal transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
//               {slide.title}
//                 </p>
//               </div>
//               {/* Resim */}
//               <div className="relative w-full flex justify-center md:justify-end">
//   <img
//     className="object-contain h-auto"
//     src={slide.img}
//     alt="Mebel"
//   />
// </div>

//             </div>
//           )}
//         </div>
//       </div>
//     </SwiperSlide>
//   ))}
// </Swiper>
// </div> 
// <div className="md:ml-[40px]">
// <p className="text-black text-[22px] md:text-[32px] font-medium mb-[16px] md:mb-[40px]">Konsultasiya</p>
// <p className="text-black text-base font-medium">AgroGurama ilə Mütəxəssis Konsultasiyası</p>
// <p className="text-black text-base font-normal">Kənd təsərrüfatında uğur və davamlı inkişaf düzgün məlumat və təcrübənin tətbiqi ilə mümkündür. AgroGurama-nın təcrübəli mütəxəssisləri, fermerlərin və kənd təsərrüfatı müəssisələrini ən son texnologiya, yeniliklər və məhsuldarlığı artırmaq üçün optimal həllərlə təmin etmək üçün burada sizə dəstək göstərir.</p>
// </div>
//    </div> 
//    <div className="flex flex-col md:flex-row justify-between items-center md:mt-[40px]">
//    <div>
// <p className="text-black text-[22px] md:text-[32px] font-medium md:mb-[40px] mb-[16px] mt-[16px] md:mt-0">Texnikaların satışı</p>
// <p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
// <p className="text-black text-base font-normal">- Ən son innovativ satış təklif edirik.</p>
// <p className="text-black text-base font-normal">- Yağ, filtr və digər istehlak materiallarının dəyişdirilməsi.</p>
// <p className="text-black text-base font-normal">- Hissələrin aşınma səviyyəsinin qiymətləndirilməsi.</p>
// </div> 
// <div className="md:w-[586px] flex flex-col justify-end mt-[20px] md:mt-0">
// <Swiper
//   spaceBetween={30}
//   centeredSlides={true}
//   autoplay={{
//     delay: 5000,
//     disableOnInteraction: false,
//   }}
//   pagination={{
//     clickable: true,
//   }}
//   navigation={true}
//   onSlideChange={(swiper) => {
//     setShowText(false);
//     setShowButton(false);
//     setActiveIndex(swiper.activeIndex);
//   }}
//   modules={[Autoplay, Pagination, Navigation]}
//   className="servicesSwiper1"
// >
//   {texnika.map((slide, index) => (
//     <SwiperSlide key={index}>
//       <div className="relative">
//         {/* Mor arka plan */}
//         <div>
//           {activeIndex === index && (
//             <div>
//               <div className="text-black text-lg font-normal mb-3">
//               <p className={`text-black md:text-lg font-normal transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
//               {slide.title}
//                 </p>
//               </div>
//               {/* Resim */}
//               <div className="relative w-full flex justify-center md:justify-end">
//   <img
//     className="object-contain h-auto"
//     src={slide.img}
//     alt="Mebel"
//   />
// </div>

//             </div>
//           )}
//         </div>
//       </div>
//     </SwiperSlide>
//   ))}
// </Swiper>
// </div> 
//    </div>
//    <div className="flex flex-col-reverse md:flex-row items-center md:mt-[40px]">
//    <div className="md:w-[586px] flex flex-col justify-end mt-[20px] md:mt-0">
// <Swiper
//   spaceBetween={30}
//   centeredSlides={true}
//   autoplay={{
//     delay: 5000,
//     disableOnInteraction: false,
//   }}
//   pagination={{
//     clickable: true,
//   }}
//   navigation={true}
//   onSlideChange={(swiper) => {
//     setShowText(false);
//     setShowButton(false);
//     setActiveIndex(swiper.activeIndex);
//   }}
//   modules={[Autoplay, Pagination, Navigation]}
//   className="servicesSwiper1"
// >
//   {satis.map((slide, index) => (
//     <SwiperSlide key={index}>
//       <div className="relative">
//         {/* Mor arka plan */}
//         <div>
//           {activeIndex === index && (
//             <div>
//               <div className="text-black text-lg font-normal mb-3">
//               <p className={`text-black md:text-lg font-normal transition-all duration-1000 transform ${showText ? 'translate-y-0 opacity-100' : '-translate-y-10 opacity-0'}`}>
//               {slide.title}
//                 </p>
//               </div>
//               {/* Resim */}
//               <div className="relative w-full flex justify-center md:justify-end">
//   <img
//     className="object-contain h-auto"
//     src={slide.img}
//     alt="Mebel"
//   />
// </div>

//             </div>
//           )}
//         </div>
//       </div>
//     </SwiperSlide>
//   ))}
// </Swiper>
// </div> 
// <div className="md:ml-[40px]">
// <p className="text-black text-[22px] md:text-[32px] font-medium md:mb-[40px] mb-[16px] mt-[16px] md:mt-0">Satış sonrası servis</p>
// <p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
// <p className="text-black text-base font-normal">- Qüsurlu və ya aşınmış hissələrin aşkarlanması.</p>
// <p className="text-black text-base font-normal">- Avadanlığın elektrik sistemlərinin diaqnostiki edilməsi.</p>
// <p className="text-black text-base font-normal">- Mexaniki və hidravlik sistemlərin yoxlanması və bərpası.</p>
// </div>
//    </div>
//    <div className="flex flex-col md:flex-row justify-between items-center md:mt-[40px]">
//    <div className="md:w-1/2">
// <p className="text-black text-[22px] md:text-[32px] font-medium md:mb-[40px] mb-[16px] mt-[16px] md:mt-0">Ehtiyat hissələrinin satışı</p>
// <p className="text-black text-base font-medium">Xidmətlərimizə daxildir:</p>
// <p className="text-black text-base font-normal">-Texnikanıza və aqreqatlara uyğun orijinal ehtiyat hissələri ilə təmin edilməsi</p>
// </div>
// <div  className="md:w-[525px] mt-[20px] md:mt-0"><img className="rounded-[12px] md:rounded-[0px]" src={ehtiyyat}></img></div>
//    </div>
//      </div>
//     </>
//   );
// }










import React from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import {
  ehtiyyat,
  konsultasiya1,
  konsultasiya2,
  konsultasiya3,
  konsultasiya4,
  konsultasiya5,
  satis1,
  satis2,
  texnika1,
  texnika2,
  texnika3,
  texnika4,
  texnika5,
  texnika6,
} from "../../assets";
import "./Services.css";

export default function Services() {
  const { t } = useTranslation();

  const servicesData = [
    {
      title: "Konsultasiya",
      description:
        "AgroGurama ilə Mütəxəssis Konsultasiyası. Kənd təsərrüfatında uğur və davamlı inkişaf düzgün məlumat və təcrübənin tətbiqi ilə mümkündür. AgroGurama-nın təcrübəli mütəxəssisləri, fermerlərin və kənd təsərrüfatı müəssisələrini ən son texnologiya, yeniliklər və məhsuldarlığı artırmaq üçün optimal həllərlə təmin etmək üçün burada sizə dəstək göstərir.",
      images: [konsultasiya1, konsultasiya2, konsultasiya3, konsultasiya4, konsultasiya5],
    },
    {
      title: "Texnikaların satışı",
      description: "Xidmətlərimizə daxildir:",
      images: [texnika1, texnika2, texnika3, texnika4, texnika5, texnika6],
      details: [
        "- Ən son innovativ satış təklif edirik.",
        "- Yağ, filtr və digər istehlak materiallarının dəyişdirilməsi.",
        "- Hissələrin aşınma səviyyəsinin qiymətləndirilməsi.",
      ],
    },
    {
      title: "Satış sonrası servis",
      description: "Xidmətlərimizə daxildir:",
      images: [satis1, satis2],
      details: [
        "- Qüsurlu və ya aşınmış hissələrin aşkarlanması. ",
        "- Avadanlığın elektrik sistemlərinin diaqnostiki edilməsi.",
        "- Mexaniki və hidravlik sistemlərin yoxlanması və bərpası.",
      ],
    },
    {
      title: "Ehtiyat hissələrinin satışı",
      description: "Xidmətlərimizə daxildir:",
      images: [ehtiyyat],
      details: [
        "- Texnikanıza və aqreqatlara uyğun orijinal ehtiyat hissələri ilə təmin edilməsi",
      ],
    },
  ];

  return (
    <div className="max-w-full md:max-w-container px-4 md:px-0 mx-auto mb-32">
      <div className="text-center">
        <button className="w-28 bg-[#d4d64f] rounded-full text-white mt-10">Xidmətlər</button>
        <h2 className="text-xl md:text-3xl font-medium mt-4">
          Ehtiyaclarınıza uyğun xidmətlər
        </h2>
        <p className="text-sm md:text-base mt-4">
          AgroGurama kənd təsərrüfat şirkəti, aqreqatlarınızın uzun müddət və
          səmərəli işləməsini təmin etmək üçün mükəmməl...
        </p>
      </div>

      {servicesData.map((service, index) => (
        <ServiceSection key={index} service={service} isReverse={index % 2 === 1} />
      ))}
    </div>
  );
}

const ServiceSection = ({ service, isReverse }) => {
  return (
    <div
      className={`flex  md:justify-between flex-col ${isReverse ? "md:flex-row" : "md:flex-row-reverse"} items-center mt-16 gap-6`}
    >
      <div className="w-full md:w-[633px] text-center md:text-left">
        <h3 className="text-lg md:text-2xl font-medium">{service.title}</h3>
        <p className="text-sm md:text-base mt-4">{service.description}</p>
        <ul className="list-inside mt-4">
          {service.details?.map((detail, idx) => (
            <li key={idx} className="text-sm md:text-base">
              {detail}
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full md:w-[525px] mt-8 md:mt-0">
        <Swiper
          spaceBetween={20}
          centeredSlides
          autoplay={{ delay: 5000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          navigation
          modules={[Autoplay, Pagination, Navigation]}
          style={{ width: "100%", height: "auto" }}
        >
          {service.images.map((img, idx) => (
            <SwiperSlide key={idx}>
              <img
                className="object-cover w-full h-[250px] md:h-auto rounded-[12px]"
                src={img}
                alt={service.title}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};











































